.header{display: block;box-shadow: 0 2px 5px 0 rgba(166, 166, 166, 0.25);position: sticky;top: 0;z-index: 8;background: #fff;}
.headContainer{padding-left: 120px;position: relative;}
.weblogoBox{position: absolute;left: 0;top: 0;bottom: 0;display: flex;align-items: center;}
.weblogoBox a{cursor: pointer;position: relative;}
.weblogoBox > img{width: 84px;}
.nav{display: flex;align-items: center; justify-content: space-between;}
.nav >ul{height: 65px;display: flex;align-items: inherit;}
.nav >ul li{margin-left: 20px;display: flex;color: #222;font-size: 14px;cursor: pointer;height: 65px;align-items: center;gap: 10px;}
.nav >ul li:first-child{margin-left: 0px;}
.nav >ul li span, .nav >ul > li a{position: relative;display: flex;align-items: center;gap: 5px;white-space: nowrap;}
/* .nav ul li .arwSmall{width: 8px;height: 8px;} */
.mtn2{margin-top: -2px;width: 8px;height: 8px;border:   solid #222222b0;border-width: 0 2px 2px 0;}
 /* .nav ul li.addArrow{padding-right: 14px;} */
.nav >ul li.addArrow:hover .arrow::after, .nav ul li.addArrow:hover a.arrow::after{transform: rotate(180deg);transition: linear 0.3s;} 
.nav >ul li.addArrow:hover .subDropdown{display: flex;}
.nav >ul li.addArrow:hover .mtn2{transform: rotate(227deg);transition: 0.3s;margin-top: 5px;}
.nav >ul li:hover a span {color: #eb5976;}
.mkStore{position: relative;display: flex;align-items: center;gap: 5px;white-space: nowrap;padding: 5px;background-image: url("data:image/svg+xml,%3Csvg width='79' height='37' viewBox='0 0 79 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='77' height='35' rx='5' fill='%23fff' stroke='%23FF8FA5' stroke-width='1' stroke-dasharray='4 4'/%3E%3C/svg%3E%0A");background-position: center;background-repeat: no-repeat;border-radius: 5px;}
.mkStore>a>p{font-size: 10px;color: #eb5976;display: flex;flex-direction: column;line-height: 12px;}
.mkStoreMobile{display: none;}
/* .nav ul li.addArrow .arrow::after, .nav ul li.addArrow a.arrow::after{content: '';pointer-events: none;position: absolute;right: -17px;top: 7px;background: url("data:image/svg+xml;charset=utf8,%3Csvg width='12' height='7' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23222222' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E") no-repeat;width: 12px;height: 7px;transition: 0.3s;} */
.nav >ul li .bookConsultation{padding: 0px 18px;line-height: 35px;font-size: 12px;color: #fff;height: 35px;border-radius: 5px;}
.nav >ul li .mobBookConsultnBtn{display: none;}
.mobLogin{display: inherit;color: #222;font-size: 12px;}
.nav >ul .subDropdown{border-radius: 0 0 5px 5px;position: absolute;top: 66px;background-color: #fff;box-shadow: 0 4px 15px 0 rgba(204, 204, 204, 0.25);line-height: normal;cursor: default;display: none;}
.nav >ul .subDropdown .heading{margin-bottom: 7px;font-size: 10px;font-weight: 600;color: #222;}
.nav >ul .subDropdown > ul{padding: 10px 0;display: block;height: inherit;position: relative;}
.nav >ul .subDropdown li{padding: 0px 10px;margin: 0px;display: block;color: #666;font-size: 12px;height: initial;line-height: 33px;transition: 0.3s;}
.nav >ul .subDropdown >ul > li > a {padding: 0 10px;border-radius: 5px;transition: 0.3s all;}
.nav >ul .subDropdown >ul > li:hover > a {background: #fff3f6;transition: 0.3s;color: #eb5976;}
/* .nav ul .subDropdown li:first-child{border: none;} */

.nav >ul .subDropdown li .menuTabInner{display: none;}
.nav >ul .subDropdown li:hover .menuTabInner{padding:15px;border-left: 0.5px solid #cecece;box-shadow: 0 4px 15px 0 rgba(204, 204, 204, 0.25);background: #fff;box-shadow: 0 4px 15px 0 hsla(0,0%,80%,.25);display:block;position:absolute;left:231px;top:0;width:594px;height: 100%;cursor: default;}
.menuGridBox{width: 100%;height: 100%;display:grid;grid-template-columns:repeat(4,1fr);gap:10px}
.menuGridBox .menuTypeMain .menuTypeHead{padding:0 10px;white-space: nowrap;display:block;font-size:12px;color:#eb5976;font-weight:600}
.menuGridBox .menuTypeMain ul{margin-top:5px;min-width: 133px;}
.menuGridBox .menuTypeMain ul>li{border-radius:5px;cursor: pointer;}
.menuGridBox .menuTypeMain ul>li>a{border-radius:5px;white-space: nowrap;display:flex;align-items:center;line-height:27px;font-size:12px;text-transform: capitalize; color:#666;transition:all .3s;}
.menuGridBox .menuTypeMain ul>li:hover{background:#fff3f6}
.menuGridBox .menuTypeMain ul>li:hover > a{color:#eb5976;background:#fff3f6}


.asideSubDropdwn.subDropdown{max-width: 392px !important;}
.asideSubDropdwn ul{display: grid !important;grid-template-columns: repeat(2,1fr);}

.loginRegister:hover .loginDropDown{display: block;}
.loginDropDown{padding: 15px;position: absolute;top: 65px;right: 0;background-color: #fff;border-radius: 8px; box-shadow: 0 4px 15px 0 rgba(204, 204, 204, 0.25);max-width: 152px;width: 100%;line-height: normal;display: none;}
.loginDropDown .loginList{padding: 0px 10px;margin: 0px !important;display: block;color: #666;font-size: 12px;height: initial;line-height: 33px;transition: 0.3s;width: 100%;}
.loginDropDown .loginList:hover {border-radius: 5px;background-color: #fff3f6;transition: 0.3s;color: #eb5976;}
.nav ul .loginDropDown ul{display: block;height: inherit;}
.loginRegister .clentHeaderThmbnl{display: flex;width: 30px;height: 30px;overflow: hidden;border-radius: 50%;}

/* mobile header css  */

.setMobileHeader{padding: 8px 0px;width: 100%;}
.logoLogin{display: flex;align-items: center;justify-content: space-between;width: 100%;}
.logoBox{display: flex;gap: 15px;position: inherit;}
.logoBox >img{width: 80px;}
.logoBox >a{padding-right: 15px;position: relative;}
.logoBox >a::before{content: '';position: absolute;right: 0;width: 1px;background: #bbb; height: 34px;top: calc(50% - 17px);}
.mkStoreMobile{display: flex;gap: 5px;overflow: hidden;}
.mobLogin .loginButton{padding: 0px 15px;line-height: 30px;}

/* mobile header css  */

@media screen and (max-width:360px) {
    .mobLogin .loginButton{font-size: 10px;padding: 2px 5px;}
}


/* mobileNavBar */

.mobileNavBar{position: fixed;bottom: 0;left: 0;width: 100%;height: 70px;z-index: 9;display: block;}
.mobileNavBar .svgSectn{padding: 0px 15px;margin: 15px 0px 0px;background-color: #fff;width: 100%;height: 100%;display: flex;align-items: center;gap: 25px;max-height: 60px;box-shadow: 0 -1px 6px 0 rgb(150 150 150 / 25%);z-index: 5;position: relative;}
.mobileNavBar .svgSectn .menuName{display: block;width: 100%;}
.mobileNavBar .svgSectn .mobBtmNav{width: 100%;text-align: center;white-space: nowrap;font-size: 8px;color: #000;position: relative;height: 35px;}
.mobileNavBar .svgSectn .mobBtmNav .call{width: 44px;height: 44px;position: absolute;background-image: linear-gradient(227deg, #eb5976 94%, #c53772 8%);border-radius: 10px;top: -25px;left: calc(50% - 22px);display: flex;align-items: center;justify-content: center;z-index: 5;}
.mobileNavBar .svgSectn .mobBtmNav em{display: flex;align-items: center;justify-content: center;}
.mobileNavBar .svgSectn .mobBtmNav.active em svg path{stroke: #EF6B85;fill: #FFC2CF;}
.mobileNavBar .svgSectn .mobBtmNav.active{font-size: 12px;}

.subMenuWithClose{background: #fff;position: absolute;width: 100%;bottom: 0;display: block;}
.subMenuSection{position: fixed;left: 0;bottom: -102vh;background: rgb(0 0 0 / 40%);z-index: 4;width: 100%;height: 100vh;transition: 0.3s;}
.subMenuSection.active{bottom: 55px;transition: 0.3s;}
.subMenuSection .subMenuWithClose > .menu::-webkit-scrollbar{width: 2px;height: 0;}
.subMenuSection .subMenuWithClose > .menu{width: 100%;max-height: 78vh;overflow: auto;}
.subMenuSection .subMenuDtls{border-bottom: solid 0.5px #e5e5e5;font-size: 14px;text-align: left;color: #666;}
.subMenuSection .subMenuDtls .mobileLogout{padding: 15px;display: block;}
.subMenuSection .subMenuDtls a{padding: 15px;display: block;}
.subMenuSection .subMenuDtls:hover{background-color: #fff4f6;}
.subMenuSection .pdLft30{padding-left: 30px;}
/* .subMenuCloseBtn{position: absolute;left: calc(50% - 20px);top: -45px;width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;background: #fff;border-radius: 50%;}
.subMenuCloseBtn .closeBtn{position: relative;width: 16px;height: 16px;top: -2px;}
.subMenuCloseBtn .closeBtn::before{content: '';position: absolute;left: 0;top: 9px;width: 100%;height:2px;background-color: #666;transform: rotate(45deg);}
.subMenuCloseBtn .closeBtn::after{content: '';position: absolute;left: 0;top: 9px;width: 100%;height:2px;background-color: #666;transform: rotate(135deg);} */
.moreSubCategory{max-height: 195px;}
.moreSubCategory.active ul{display: block;}
/* .moreSubCategory.active .moreSubCategoryHeading span{transform: rotate(180deg);transition: 0.3s;} */
.moreSubCategoryHeading{padding: 15px;border-bottom: solid 0.5px #e5e5e5;display: flex;align-items: center;justify-content: space-between;text-transform: capitalize;}
.moreSubCategoryHeading + UL > li{padding-left: 15px;}
.moreSubCategoryHeading .subMenuDtls{border-bottom: none;}
.moreSubCategoryHeading span{transition: 0.3s;}
.moreSubCategory ul{max-height: 125px;overflow: auto;display: none;background-color: #fff;}
.moreSubCategory ul li a{padding: 15px 25px;}

@media screen and (max-width:1024px) {
    .mobileNavBar .svgSectn .mobBtmNav{font-size: 10px;}
}


.mobLayout{display: flex;align-items: flex-end;height:auto;min-height:100%;position:relative;background:#fff;z-index:2}
.menu .menuItem {border-top: solid 0.5px #e5e5e5;}
.menu .menuItemflx {display: flex;}
.menu .menuItem a {display:flex;align-items:center;height:38px;}
.menu .menuItem > .menuHead{font-size: 12px;font-weight: 600;color: #222;display:flex;align-items:center;height:38px;padding:0 16px;}
.menu .menuItem > a >.viewAll{font-size: 12px;font-weight: 600;color: #eb5976;display:flex;align-items:center;height:38px;padding:0 3px;}
.menu .menuItem >.viewAll{font-size: 12px;font-weight: 600;color: #eb5976;display:flex;align-items:center;height:38px;padding-left: 32px;}
.menu .menuItem a .menuTitle{text-align: left;font-size: 12px;color:#666;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;flex-grow:1;transition:color .3s}
.menu .menuItem.subMenu{position:relative}
.menu .menuItem.subMenu> .menuHead::before{content:'';position:absolute;top:6px;right:16px;top: 20px; width:14px;height:2px;background:#4b4b4b;-webkit-transform:rotate(90deg);transform:rotate(90deg);-webkit-transition:all .2s ease-in-out;transition:all .2s ease-in-out}
.menu .menuItem.subMenu> .menuHead::after{content:'';position:absolute;top:6px;right:16px;top: 20px; width:14px;height:2px;background:#4b4b4b;-webkit-transition:all .2s ease-in-out;transition:all .2s ease-in-out}
.menu .menuItem.subMenu.active> .menuHead::after{-webkit-transform:rotate(-180deg);transform:rotate(-180deg);}
.menu .menuItem.subMenu.active> .menuHead::before{display: none;}
.menu .menuItem.subMenu.active > .menuHead{color:#eb5976;}

.menu .menuItem.subMenu>.subMenuList{display:none;}
.menu .menuItem.subMenu.active>.subMenuList{display: block;}

.menu .menuItem.subMenu.active>.subMenuList .menuItem .menuHead{margin-left: 16px;color: #666;}
.menu .menuItem.subMenu.active>.subMenuList .menuItem .menuTitle{margin-left: 32px;}

.listingHeader{display:flex;align-items:center;padding:15px;background-color:#f8f8f8;border-bottom:1px solid #e0e0e0;}
.listingHeader .backButton svg{margin-right:4px;}
.listingHeader .backButton{margin-left: auto;background:none;border:none;cursor:pointer;display:flex;align-items:center;font-size:12px;color:#333;}
.listingHeader .popupHeading{font-size:14px;font-weight:600;color:#222;margin-left:10px}
.listCloseBtn{margin-left: auto;}
.listCloseBtn .closeBtn{display: block;position: relative;width: 16px;height: 16px;}
.listCloseBtn .closeBtn::before{content: '';position: absolute;left: 0;top: 9px;width: 100%;height:2px;background-color: #666;transform: rotate(45deg);}
.listCloseBtn .closeBtn::after{content: '';position: absolute;left: 0;top: 9px;width: 100%;height:2px;background-color: #666;transform: rotate(135deg);}